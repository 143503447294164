

$primary-color:        #F57D02;
//
// Color system
//

$primary: $primary-color;


// Alerts
//
// Define alert colors, border radius, and padding.

/* $alert-padding-y:                   .75rem !default;
$alert-padding-x:                   1.25rem !default;
$alert-margin-bottom:               1rem !default;
$alert-border-radius:               25px !default;
// $alert-link-font-weight:            $font-weight-bold !default;
$alert-border-width:                0 !default;

$alert-bg-level:                    -9 !default;
$alert-border-level:                -9 !default;
$alert-color-level:                 9 !default;
 */
// Slick
$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
$slick-arrow-color: black;
$slick-dot-color: black;

// intlTelInput
$flagsImagePath: "../../build/img/" !default;

// Bootstrap overrides
$border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;
